import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

function Includes({ ispackage }) {
  const {
    Includes: { edges },
  } = useStaticQuery(graphql`
    query features {
      Includes: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/includes/" } }
        sort: { fields: [frontmatter___id], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              id
              title
              discription
              path
              buttontext
              icon {
                publicURL
              }
            }
          }
        }
      }
    }
  `);
  return (
    <div className="bg-comparision">
      <div className="max-w-6xl mx-auto  py-32 px-5 md:px-8">
        <h2 className="text-blackish font-bold text-3xl lg:text-4xl text-center">
          Also included in every package
        </h2>

        <div
          className={`flex ${
            ispackage ? "justify-between" : "justify-center"
          }  flex-wrap w-full mt-16 lg:mt-32`}
        >
          {edges.map((edge, index) => {
            const {
              title,
              icon,
              buttontext,
              discription,
              path,
            } = edge.node.frontmatter;
            return (
              <div
                className={`w-full  ${
                  ispackage && index === 2 && "hidden "
                } lg:w-1/2  ${index === 2 ? "mb-0" : "mb-24"}`}
              >
                <div
                  className={`flex px-3 ${
                    ispackage ? "w-full lg:w-11/12" : "w-full"
                  } ${ispackage ? "package-include" : ""} `}
                >
                  <div className="flex justify-center w-full">
                    <div className="w-1/5">
                      <img alt={`${title}-icon`} src={icon.publicURL}></img>
                    </div>
                    <div className="pl-4 w-4/5 lg:w-3/5 lg:py-4">
                      <h3 className="text-2xl lg:text-4xl text-blackish font-bold">
                        {title}
                      </h3>
                      <p
                        className={`mt-8 text-textcolor text-lg ${
                          index !== 2 && "h-24"
                        }`}
                      >
                        {discription}
                      </p>

                      {index !== 2 ? (
                        <Link to={path}>
                          <button
                            className={`bg-theme text-sm text-white btn-purple-on-white rounded-md mt-8 w-3/5 md:w-2/5 ${
                              ispackage ? "lg-w-3/5" : "xl:w-2/5"
                            } lg:w-3/5  py-3 outline-none`}
                          >
                            {ispackage ? "Learn More" : `${buttontext}`}
                          </button>
                        </Link>
                      ) : (
                        <a
                          href="https://elementsoftworks.co.uk/merlin.pdf"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <button
                            className={`bg-theme text-sm text-white btn-purple-on-white rounded-md mt-8 w-3/5 md:w-2/5 ${
                              ispackage ? "lg-w-3/5" : "xl:w-2/5"
                            } lg:w-3/5  py-3 outline-none`}
                          >
                            {ispackage ? "Learn More" : `${buttontext}`}
                          </button>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Includes;
